$textColor: #000000;
$primaryColor: #0098fe;
$secondaryColor: #c4d4d3;
$secondaryLightColor: #f2f6f6;
$redColor: #f45467;
$errorColor: #ff1055;

$borderRadius: 6px;
$fieldHeight: 2.75rem;

$gradientBlue: linear-gradient(45deg, rgba(0,152,254,1) 0%, rgba(0,184,255,1) 100%);
$gradientGreen: linear-gradient(45deg, rgba(0,212,212,1) 0%, rgba(61,243,214,1) 100%);
$gradientOrange: linear-gradient(45deg, rgba(255,180,2,1) 0%, rgba(253,211,103,1) 100%);
$gradientRed: linear-gradient(45deg, rgba(241,108,110,1) 0%, rgba(255,163,129,1) 100%);
$gradientPink: linear-gradient(45deg, rgba(255,21,74,1) 0%, rgba(255,107,129,1) 100%);
$gradientLightBlue: linear-gradient(45deg, rgba(0,212,212,1) 0%, rgba(57,242,214,1) 100%);
$gradientGrey: linear-gradient(45deg, rgba(236,242,241,1) 0%, rgba(236,242,241,1) 100%);

$sideMargin: 6.25rem; // 100px

$animationTime: 0.3s;