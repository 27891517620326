.marker {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px 0 #9db7b5;
}

.offer-marker {
  z-index: 2;

  &.single,
  &.hovered,
  &:hover {
    z-index: 3;

    .offer-box {
      opacity: 1;
    }
  }

  .icon-wrapper {
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

    .icon {
      color: white;
      font-size: 1.25rem;
    }

    .icon-favourite {
      width: 19px;
      height: 19px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
      background-color: white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0px;
      right: 0px;
      transform: translate(50%, 50%);
      color: #ff154a;

      .material-icons {
        font-size: 1em;
      }
    }
  }

  &.category-restaurant {
    .icon-wrapper {
      transform: translate(0px, 2px);
    }
  }

  .offer-box {
    position: absolute;
    display: block;
    width: 15rem;
    font-family: 'Montserrat';
    pointer-events: none;
    opacity: 0;
    top: 0px;
    left: 50%;
    transform: translate(-50%, calc(-100% - 2rem));
    z-index: 2;

    &:after {
      width: 26px;
      height: 26px;
      background: url('../../assets/images/map-modal-arrow.png');
      position: absolute;
      bottom: 0px;
      left: 50%;
      content: '';
      display: block;
      transform: translate(-50%, calc(100% - 1px));
    }

    // transition: opacity 0.3s, transform 0.3s;


    .image {
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-top-left-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
      height: 7.5rem;
      width: 100%;
    }

    .title {
      border-left: 1px solid $secondaryColor;
      border-right: 1px solid $secondaryColor;
      font-weight: bold;
      text-align: center;
      padding: 1rem 0.5rem;
      background: white;
      font-size: 12px;
    }

    .cashback-description {
      border-width: 0px 1px 1px 1px;
      border-color: $secondaryColor;
      border-style: solid;
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      background: #ebfcfb;
      text-align: center;
      padding: 1rem 0.5rem;
      font-weight: bold;
      color: $primaryColor;
      font-size: 12px;
    }
  }
}

.location-marker {
  z-index: 1;
  background: white;

  .icon-wrapper {
    .icon {
      color: $primaryColor;
      transform: translateY(2px);
      font-size: 22px;
    }
  }
}