#header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 7;
}

#filters {
  position: fixed;
  left: 0px;
  width: 100%;
  z-index: 7;
}

#view-settings {

}

#map {
  position: fixed;
  right: 0px;
  z-index: 5;
  width: 33.3333%;
}

.offers {
  padding-bottom: 2rem;
}