.loading-wrapper {
  position: relative;

  .loading-container {
    position: fixed;
    left: 0px;
    width: 100%;
    pointer-events: none;

    &.full-window {
      top: 0px;
      height: 100%;
    }

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      display: block;
      z-index: 6;
      background: rgba(255, 255, 255, 0.5);
  
      opacity: 0;
      transition: opacity $animationTime;
    }
  }

  &.visible {
    
    .loading-container {
      z-index: 6;
      pointer-events: all;
      
      &:before {
        opacity: 1;
      }
    }

    .loading {
      z-index: 10;
      opacity: 1;
    }
  }

  .loading {
    width: 12.5rem;
    height: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    
    opacity: 0;
    z-index: 0;

    transition: opacity $animationTime;

    img {
      display: block;
      margin-bottom: 2rem;
    }

    .label {
      color: $secondaryColor;
    }

    
  }
}