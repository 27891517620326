.offers {
  .grid-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;

    .grid-item-container {
      
    }

    &.hovered,
    &:hover {
      .title-standalone {
        opacity: 0;
      }

      .cashback-small {
        opacity: 0;
        bottom: -3rem;
      }

      .full-content {
        opacity: 1;
      }

      .cashback-big {
        opacity: 1;
        bottom: 0px;
      }
    }

    .title-standalone {
      color: white;
      font-weight: bold;
      text-shadow: 0 2px 10px #252b48;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      transition: opacity $animationTime;
    }

    .cashback-small {
      padding: 1rem 1.5rem;
      display: block;
      color: white;
      font-weight: bold;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.75em;

      transition: opacity $animationTime, bottom $animationTime;
    }

    .cashback-big {
      opacity: 0;
      font-size: 0.875em;
      font-weight: bold;
      width: 100%;
      bottom: -3rem;
      left: 0px;
      position: absolute;
      padding: 1.5rem 0px;
      text-align: center;
      z-index: 2;
      color: white;

      transition: opacity $animationTime, bottom $animationTime
    }

    .full-content {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(37, 43, 72, 0.8);
      padding: 0px 2rem 4rem 2rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      transition: opacity $animationTime;

      .title {
        color: white;
        padding-bottom: 1rem;
        font-weight: bold;
      }

      .address {
        color: white;
        padding-bottom: 1.25rem;
      }

      .description {
        color: white;
        line-height: 2em;
      }
    }
  }
}
