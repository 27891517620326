.offer-background {
  background: url('http://www.allwhitebackground.com/images/6/Gradient-Background-Free-Images.jpeg');
  background-repeat: repeat;
  background-position: center;

  .offer-background-inner {
    background-size: 100%;
    background-repeat:  no-repeat;
    background-position: top center;
    position: relative;
    padding-top: 40rem; // 38.75
    padding-bottom: 3rem;
  }

  .come-back {
    backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5.625rem;
    top: 1.25rem;

    .icon {
      color: white;
      font-size: 1.4em;
    }
  }

  .logo-wrapper {
    height: 4.063rem;
    padding: 0.5rem 1.5rem;
    background: white;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    text-align: center;
    border-top: 1px solid #ebebeb;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.offer-full-content {
  box-shadow: 0 8px 24px 0 rgba(0, 9, 89, 0.12);
  border-radius: 12px;
  background: white;
  width: 50%;
  margin: 0px auto;

  .cashback-information {
    text-align: center;
    padding: 1.25rem 0px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    .text {
      color: white;

      .icon {
        margin-right: 1rem;
      }
    }
  }

  .offer-section {
    padding: 1.5rem 1.75rem;

    .section-header {
      color: $secondaryColor;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.07em;
    }

    &.header {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .title {
        padding-bottom: 1.25rem;
      }
    }

    &.rating-and-favourite {
      border-width: 1px 0px;
      border-style: solid;
      border-color: #dde2e7;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .subsection-rating {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .section-header {
          margin-right: 1rem;
        }

        .rating {
          transform: translateY(-2px);
        }

        .results {
          margin-left: 0.75rem;
          letter-spacing: 0.05em;

          .value {
            font-weight: bold;
          }

          .votes {
            font-weight: 400;
            padding-left: 0.25rem;
            display: inline-block;
          }
        }
      }

      .subsection-add-to-favourites {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .section-header {
          margin-right: 1rem;
        }
      }
    }

    &.contact-opening-hours {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .subsection-contact {
        .section-header {
          margin-bottom: 1.5rem;
        }

        .contact-details {
          li {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 0.5rem;
            font-weight: 500;

            &:last-child {
              margin-bottom: 0px;
            }

            .icon {
              width: 2.25rem;
              transform: translateY(-4px);
              color: $secondaryColor;
            }

            a {
              color: $primaryColor;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .subsection-opening-hours {
        .section-header {
          margin-bottom: 1.5rem;
        }

        .opening-hours {
          > li {
            font-size: 0.75em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0px;
            border-bottom: 1px solid #dde2e7;

            &:last-child {
              border-bottom: 0px;
            }

            .weekday {

            }

            .hours {

            }
          }
        }
      }
    }

    
  }

  #offer-map {
    height: 30rem;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
};