.list-item {
  $innerPadding: 1.5rem;
  display: flex;
  box-shadow: 0 8px 24px 0 rgba(55, 108, 152, 0.1);
  margin-bottom: 2rem;

  transition: box-shadow 0.3s;

  &.hovered {
    box-shadow: 0 8px 24px 0 rgba(55, 108, 152, 0.5);
  }

  .list-item-link {
    display: flex;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .image {
    flex: 0 0 12.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    .heart {
      display: none;
    }
  }

  .data {
    flex-grow: 1;

    .cashback-info {
      background: #ebfafb;
      color: #4dd0d2;
      font-weight: bold;
      padding: 1rem $innerPadding 1rem $innerPadding;

      .icon {
        display: inline-block;
        margin-right: 1rem;
        font-size: 1.5em;
        transform: translateY(2px);
      }
    }

    .content {
      padding: $innerPadding;

      .title {
        color: $textColor;
        padding-bottom: 1rem;
      }

      .contact-information {
        padding-bottom: 1.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.75em;

        > li {
          color: $textColor;
          margin-right: 2rem;

          &:last-child {
            margin-right: 0px;
          }

          .icon {
            color: $secondaryColor;
            display: inline-block;
            margin-right: 0.5rem;
            font-size: 1.5em;
            transform: translateY(3px);
          }
        }
      }

      .description {
        color: $textColor;
      }
    }
  }
}