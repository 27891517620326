.field-common {
  border-radius: $borderRadius;
  border: 1px solid $secondaryColor;
  box-sizing: border-box;
  position: relative;

  &.short {
    $fieldWidth: 6.25rem;
    
    width: $fieldWidth;

    .options-wrapper {
      width: $fieldWidth;
    }
  }

  &.medium {
    $fieldWidth: 11rem;
    
    width: $fieldWidth;

    .options-wrapper {
      width: $fieldWidth;
    }
  }

  &.long {
    $fieldWidth: 17rem;

    width: $fieldWidth;

    .options-wrapper {
      width: $fieldWidth;
    }
  }

  &.full-width {
    width: 100%;

    .options-wrapper {
      width: 100%;
    }
  }

  > .label {
    color: $secondaryColor;
    position: absolute;
    background-color: white;
    padding: 0px 5px;
    display: block;
    position: absolute;
    top: 0px;
    transform: translateY(-50%);
    left: calc(1rem - 5px);
    z-index: 4;
  }

  .field {
    height: $fieldHeight;
    position: relative;
    width: 100%;
    z-index: 2;

    input[type="password"],
    input[type="telephone"],
    input[type="text"] {
      height: $fieldHeight;
      width: 100%;
      border-radius: $borderRadius;
      padding: 0px 2.5rem 0px 1rem;
      box-sizing: border-box;
      font-size: 0.875em;
      font-weight: 500;
    }

    .icon {
      color: $primaryColor;
      position: absolute;
      z-index: 3;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }

    &.field-password {
      .icon {
        font-size: 1.25em;
        color: $secondaryColor;

        &.active {
          color: $primaryColor;
        }
      }
    }
  }
}

.text-field {
  &.is-error {
    border-color: $errorColor;

    .error {
      color: $errorColor;
    }
  }

  .field-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 1rem 0px 1rem;

    .error {
      align-self: flex-start;
      font-weight: 500;
    }

    .bottom-label {
      align-self: flex-end;
      color: $secondaryColor;
      font-weight: 500;
    }
  }
}

.select-field { 
  cursor: pointer;

  &.choosen {
    border-color: $secondaryLightColor;

    > .label {
      display: none;
    }

    .field input[type="text"],
    .current-value {
      background-color: $secondaryLightColor;
    }
  }
  .current-value {
    border-radius: $borderRadius;
    height: $fieldHeight;
    background: white;
    // line-height: $fieldHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      padding-left: 1rem;
      color: $textColor;
      font-weight: 500;
      display: block;

      .icon-on-left {
        margin-right: 0.5rem;
        display: inline-block;
        transform: translateY(1px);
      }
    }

    .icon {
      margin-right: 1rem;
      color: $primaryColor;
    }
  }

  .options-wrapper {
    position: absolute;
    bottom: 0px;
    transform: translateY(calc(100% + 1rem));
    border-radius: $borderRadius;
    border: 1px solid $secondaryColor;
    box-sizing: border-box;
    z-index: 10;

    .options {
      .option {

        &:first-child {
          .option-button {
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;

          }
        }

        &:last-child {
          .option-button {
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
          }
        }

        &.current .option-button,
        .option-button:hover {
          background-color: $secondaryLightColor;
          color: $primaryColor;
        }


        .option-button {
          display: block;
          width: 100%;
          padding: 1rem;
          font-size: 0.875em;
          font-weight: 500;
          color: $textColor;
          background: white;
          text-align: left;

          box-sizing: border-box;
        }
      }
    }
  }

  &.select-search-field {
    .options {
      .option {
        .option-button {
          &.option-current-location {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
              font-size: 1.25em;
              font-weight: 500;
            }
          }
        }
      }
    }  
  }
}

.main-button {
  border-radius: $borderRadius;
  height: $fieldHeight;
  color: white;
  font-weight: 700;
  font-size: 0.875em;
  padding: 0px 2.5rem;
  box-sizing: border-box;

  &.full-width {
    width: 100%;
    text-align: center;
  }
}