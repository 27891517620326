.welcome-page {
  $innerPadding: 4.375rem;

  display: flex;
  min-height: 100vh;
  width: 100%;

  .content {
    flex: 0 0 35%;
    background: white;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    display: flex;

    .inner {
      width: 100%;
      padding: $innerPadding;

      box-sizing: border-box;
    }

    .logo {
      display: block;
      margin-bottom: 2rem;
    }

    .title {
      font-weight: bold;
      margin-bottom: 2.5rem;
    }

    .field-wrapper {
      margin-bottom: 2rem;

      &.small-margin {
        margin-bottom: 0.5rem;
      }

      &.center {
        display: flex;
        justify-content: center;
      }
    }
  }

  .background {
    flex: 0 0 65%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .text {
      color: white;
      font-weight: bold;
      width: 50%;
      padding-left: 4rem;
      padding-top: 20%;

      box-sizing: border-box;
    }

    .title {
      margin-bottom: 2rem;
    }

    .subtitle {
      
    }
  }
}

.login-page {
  .reset-password {
    display: flex;
    justify-content: flex-end;
    font-weight: 600;

    a {
      color: $primaryColor;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}