.icon-button {
  background: transparent;
  color: $secondaryColor;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius;

  &.active {
    background: $secondaryLightColor;
    color: $primaryColor;
  }

  .icon {
    font-weight: 500;
  }
}