.reset-password-actions {
  display: flex;

  li {
    flex: 0 0 50%;

    box-sizing: border-box;

    &:nth-child(1) {
      padding-right: 0.5rem;
    }

    &:nth-child(2) {
      padding-left: 0.5rem;
    }
  }
}