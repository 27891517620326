body {
  font-family: 'Montserrat';
}

.gradient-green {
  background: $gradientGreen;
}

.gradient-orange {
  background: $gradientOrange;
}

.gradient-red {
  background: $gradientRed;
}

.gradient-blue {
  background: $gradientBlue;
}

.gradient-pink {
  background: $gradientPink;
}

.gradient-light-blue {
  background: $gradientLightBlue;
}

.gradient-grey {
  background: $gradientGrey;
  color: black !important;
}

h1 {
  font-size: 1.75em; // 28px
}

h2 {
  font-size: 1.25em; // 20px
}

h3 {
  font-size: 1.125em; // 18px
}

h4 {
  font-size: 1em; // 16px
}

h5 {
  font-size: 0.875em; // 14px
}

p {
  font-size: 0.75em; // 12px
}

h6 {
  font-size: 0.625em;
}

p {
  a {
    color: $primaryColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
