.separator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0px;

  &:after {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    position: absolute;
    top: 50%;
    left: 0px;
    content: '';
    display: block;
    letter-spacing: 0.05rem;
  }

  .text {
    color: #7f7f7f;
    padding: 0px 1.125rem;
    text-transform: uppercase;
    background: white;
    display: block;
    position: relative;
    z-index: 5;
    font-weight: 500;
  }
}